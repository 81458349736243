/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 *
 * Website layout
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "./layout.css";

const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
      query SiteTitleQuery {
         site {
            siteMetadata {
               title
            }
         }
      }
   `);

   return (
      <>
         <div className="a11y">
            <a href="#content">Skip to content</a>
         </div>
         <hr />
         <div className="sectionWrapper">
            <Header siteTitle={data.site.siteMetadata.title} />
         </div>
         <hr />
         <div className="sectionWrapper">
            <main className="content" id="content">
               {children}
            </main>
         </div>
         <hr />
         <div className="sectionWrapper">
            <footer className="footer">
               <div className="footer-box">
                  <h2 className="footer-title">Follow me</h2>
                  <ul className="menu">
                     <li className="menu-item">
                        <a
                           className="menu-link"
                           href="https://www.youtube.com/@InLoveWithEarth"
                           title="Follow Elia on YouTube [external link]"
                        >
                           YouTube
                        </a>
                     </li>
                  </ul>
               </div>
               <div className="footer-box">
                  <h2 className="footer-title">My work</h2>
                  <ul className="menu">
                     <li className="menu-item">
                        <a
                           className="menu-link"
                           href="https://github.com/EliaContini"
                           title="Elia on GitHub [external link]"
                        >
                           GitHub
                        </a>
                     </li>
                     <li className="menu-item">
                        <a
                           className="menu-link"
                           href="https://speakerdeck.com/EliaContini"
                           title="Elia on Speaker Deck [external link]"
                        >
                           Speaker Deck
                        </a>
                     </li>
                  </ul>
               </div>
               <div className="footer-box footer-box--double">
                  <p>&copy; 1999 - {new Date().getFullYear()} Elia Contini.</p>
                  <p>
                     The content of this website is licensed under a {` `}
                     <a
                        className="link--external"
                        href="http://creativecommons.org/licenses/by-nc-nd/4.0/deed.en_US"
                        rel="license"
                        title="External link"
                     >
                        <abbr title="Creative Commons Attribution-NonCommercial-NoDerivatives">
                           CC BY-NC-ND
                        </abbr>
                        {` `}4.0 International License
                     </a>
                     .
                  </p>
                  <p>
                     Built with{" "}
                     <a
                        className="link--external"
                        href="https://www.gatsbyjs.com/"
                        title="External link"
                     >
                        Gatsby
                     </a>
                  </p>
               </div>
            </footer>
         </div>
      </>
   );
};

Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Layout;
