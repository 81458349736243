/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 *
 * Search Engine Optimization and social cards
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ blogPostMeta, description, lang, meta, location, title }) {
   const { site } = useStaticQuery(
      graphql`
         query {
            site {
               siteMetadata {
                  title
                  description
                  author {
                     name
                     summary
                  }
                  siteUrl
                  social {
                     twitter
                  }
               }
            }
         }
      `
   );

   const metaDescription = description || site.siteMetadata.description;
   const metaImageAlt = blogPostMeta ? blogPostMeta.card_image_alt : `*`;
   const metaImageBaseUrl = site.siteMetadata.siteUrl + "/images/";
   const metaImageId = blogPostMeta ? blogPostMeta.card_image : null;
   const metaLocation = location
      ? `${site.siteMetadata.siteUrl}${location.pathname}`
      : site.siteMetadata.siteUrl;

   let metaImage = null;
   switch (metaImageId) {
      case "css": {
         metaImage = metaImageBaseUrl + "card_css.png";

         break;
      }
      case "design": {
         metaImage = metaImageBaseUrl + "card_design.png";

         break;
      }
      case "html": {
         metaImage = metaImageBaseUrl + "card_html.png";

         break;
      }
      case "javaScript": {
         metaImage = metaImageBaseUrl + "card_javaScript.png";

         break;
      }
      case "python": {
         metaImage = metaImageBaseUrl + "card_python.png";

         break;
      }
      case "server": {
         metaImage = metaImageBaseUrl + "card_server.png";

         break;
      }
      case "tech": {
         metaImage = metaImageBaseUrl + "card_tech.png";

         break;
      }
      case "typeScript": {
         metaImage = metaImageBaseUrl + "card_typeScript.png";

         break;
      }
      default: {
         metaImage = metaImageBaseUrl + "card_default.png";
      }
   }

   return (
      <Helmet
         htmlAttributes={{
            lang,
         }}
         title={title}
         titleTemplate={`%s`}
         link={[
            {
               href: metaLocation,
               rel: "canonical",
            },
         ]}
         //titleTemplate={`%s | ${site.siteMetadata.title}`}
         meta={[
            {
               name: `description`,
               content: metaDescription,
            },
            {
               property: `og:description`,
               content: metaDescription,
            },
            {
               property: `og:image`,
               content: metaImage,
            },
            {
               property: `og:image:alt`,
               content: metaImageAlt,
            },
            {
               property: `og:title`,
               content: title,
            },
            {
               property: `og:type`,
               content: `website`,
            },
            {
               property: `og:url`,
               content: metaLocation,
            },
            {
               name: `twitter:card`,
               content: `summary`,
            },
            {
               name: `twitter:creator`,
               content: site.siteMetadata.social.twitter,
            },
            {
               property: `twitter:image`,
               content: metaImage,
            },
            {
               property: `twitter:image:alt`,
               content: metaImageAlt,
            },
            {
               name: `twitter:site`,
               content: site.siteMetadata.social.twitter,
            },
            {
               name: `twitter:title`,
               content: title,
            },
            {
               name: `twitter:description`,
               content: metaDescription,
            },
         ].concat(meta)}
      />
   );
}

SEO.defaultProps = {
   lang: `en`,
   meta: [],
   description: ``,
};

SEO.propTypes = {
   description: PropTypes.string,
   lang: PropTypes.string,
   meta: PropTypes.arrayOf(PropTypes.object),
   title: PropTypes.string.isRequired,
};

export default SEO;
