/**
 * Author : Elia Contini - elia.contini.page
 * Created: 21 Mar 2020
 * 
 * Website header and navigation
 */

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from "./../images/elia_contini_logo.png"

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header-title">
      <Link title="Back to the home page" to="/">
        <img
          className="header-logo"
          src={logo}
          alt={siteTitle}
          width="32"
          height="32"
        />
      </Link>
    </div>
    <hr />
    <nav className="navigation" aria-labelledby="mainNavHeader">
      <h2 className="a11y" id="mainNavHeader">
        Menu
      </h2>
      <div className="navigation-smallScreen" tabIndex="-1">
        Menu
      </div>
      <ul className="menu menu--horizontal">
        <li className="menu-item">
          <Link
            activeClassName="menu-link--active"
            className="menu-link"
            partiallyActive={true}
            to="/blog/"
          >
            Blog
          </Link>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
